var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.loadingOverlay,"is-full-page":true,"loader":"bars"}}),_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(_vm._s(_vm.$t('label.cargoGroupList')))])]),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.cargoGroup'),
              placement: 'top-end',
            }),expression:"{\n              content: $t('label.cargoGroup'),\n              placement: 'top-end',\n            }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":_vm.toggleShowModalCreateGpo}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v(_vm._s(_vm.$t('label.nuevo'))+" ")],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"fields":_vm.fields,"items":_vm.computedGrupoCargaList,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingTable,"sorter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"cell-center text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.listMeasurementUnits'),
                  placement: 'top-end',
                }),expression:"{\n                  content: $t('label.listMeasurementUnits'),\n                  placement: 'top-end',\n                }"}],staticClass:"mr-1",attrs:{"square":"","color":"watch","size":"sm"},on:{"click":function($event){return _vm.showModalUnits(item.GpoCargoId)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.edit')+' '+_vm.$t('label.cargoGroup'),
                  placement: 'top-end',
                }),expression:"{\n                  content: $t('label.edit')+' '+$t('label.cargoGroup'),\n                  placement: 'top-end',\n                }"}],attrs:{"square":"","color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleShowModalEditGpo(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1),_c('modalGpoCarga',{attrs:{"modal":_vm.showModal,"isEdit":_vm.isEdit,"GpoCargaSelected":_vm.gpoCargaSelected},on:{"update:modal":function($event){_vm.showModal=$event},"updated-modal":_vm.updateShowModalCreate,"refresh-data-table":_vm.mountedGrupoCarga}}),_c('modalUnitMeasure',{attrs:{"modal":_vm.showModalUnidades,"gpoCargaId":_vm.selectedGpoId},on:{"update:modal":function($event){_vm.showModalUnidades=$event},"updated-modal-unidades":_vm.updateShowModalUnitsMeasure}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }