<template>
  <div>
    <CModalExtended
      :title="$t('label.unitsOfMeasurement')+': '+$v.GpoCargoName.$model"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      size="lg"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
     

      <!-- #### Table ####   -->
      <CRow class="m-2">
       <CCol sm="12">
       <dataTableExtended
           class="align-center-row-datatable"
           hover
           sorter
           small
           column-filter
           :table-filter="tableText.tableFilterText"
           :items-per-page-select="tableText.itemsPerPageText"
           :items="computedUnitMeasureList"
           :fields="fields"
           :noItemsView="tableText.noItemsViewText"
           :items-per-page="5"
           pagination
          :loading="loadingTable"
        >
        <template #loading>
          <loading/>
        </template>
        </dataTableExtended>
           </CCol>
      </CRow>
      <template #footer>
        <CButton color="wipe" @click="closeModal(false)">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import { required, maxLength, helpers, alpha } from 'vuelidate/lib/validators';
import GeneralMixin from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';

//### Const para las opciones de tabla ###
const tableTextHelpers = {
  tableFilterText: {
    label: 'Filtrar:',
    placeholder: ' ',
  },
};

//### Valores de la data inicial ###
function data() {
  return {
    showModal: false,
    GpoCargoName: '',
    loadingOverlay: false,
    dataGpoCarga: [],
    loadingTable: false,
  };
}
function fields() {
  return [
    { label: '#', key: 'Nro', sorter: false, filter: false },
    {
      label: this.$t('label.measurementUnits'),
      key: 'TpUnitMeasureName',
      _classes: 'text-uppercase',
    },
    {
      label: this.$t('label.measurementUnitList'),
      key: 'UnitMeasureDetail',
      _classes: 'text-uppercase',
    },
  ];
}

//### Function para cerrar el modal ###
function closeModal(event) {
  this.showModal = false;
  this.dataGpoCarga = [];
  this.$emit('updated-modal-unidades', event);
}

//### Function para iniciar la data de la tabla ###
async function mountedGpoCargaId(id) {
  this.loadingTable = true;
  await this.$http
    .get(`GpoCargo-by-id?GpoCargoId=${id}`)
    .then((response) => {
      const tpUnitMeasures = response.data.data[0].TpUnitMeasureJson;
      if (tpUnitMeasures !== null && tpUnitMeasures.length > 0) {
        tpUnitMeasures.map((item) => {
          if (item.Status === 'ACTIVO') {
            this.dataGpoCarga.push(item);
          }
        });
      }
      this.GpoCargoName = response.data.data[0].GpoCargoName;
    })
    .finally(() => {
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//### Function para iniciar la data de la tabla ###
function computedUnitMeasureList() {
  if (this.dataGpoCarga.length > 0) {
    return this.dataGpoCarga.map((item, index) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        initialActive: index === 0 ? true : false,
        Nro: index + 1,
      };
    });
  }
}

export default {
  name: 'modalUnitMeasure',
  props: { modal: null, gpoCargaId: String },
  data,
  components: {},
  mixins: [GeneralMixin],
  directives: UpperCase,
  methods: {
    closeModal,
    mountedGpoCargaId,
  },
  validations: {
    GpoCargoName: { required, maxLength: maxLength(60) },
  },
  computed: {
    computedUnitMeasureList,
    fields,
  },
  watch: {
    modal: function(val) {
      this.showModal = val;
    },
    gpoCargaId: function(val) {
      this.mountedGpoCargaId(val);
    },
  },

  
};
</script>
<style lang="scss">
.footer-imdg {
  display: none !important;
}
</style>
